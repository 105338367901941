import React from 'react';

function Logo(props) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 256 256"
      {...props}
    >
      <path
        d="M66.22,19.23c2.37,0.1,4.54,0.26,6.71,0.27c1.4,0,2.81-0.13,4.13-0.7c0.73-0.32,1.38-0.75,1.97-1.28
  c1.6-1.41,2.85-3.13,4.12-4.82c1.28-1.69,2.58-3.37,4.13-4.82c2.01-1.88,4.36-2.8,7.14-2.62c1.39,0.09,2.73,0.38,4.05,0.8
  c1.97,0.63,3.91,1.33,5.89,1.92c1.58,0.47,3.18,0.91,4.85,0.93c1.11,0.01,2.14-0.28,3.14-0.72c1.79-0.79,3.39-1.9,4.97-3.03
  c1.69-1.2,3.38-2.38,5.2-3.38c1.57-0.86,3.23-1.46,5.03-1.6c1.38-0.11,2.7,0.17,3.99,0.65c2.41,0.88,4.47,2.37,6.56,3.8
  c1.2,0.82,2.38,1.66,3.61,2.44c1.14,0.72,2.35,1.33,3.68,1.66c0.89,0.22,1.77,0.26,2.64,0.12c1.61-0.24,3.18-0.65,4.72-1.18
  c1.72-0.59,3.45-1.17,5.19-1.7c1.49-0.46,3.02-0.73,4.59-0.72c2.47,0,4.55,0.97,6.34,2.61c1.55,1.42,2.81,3.1,4.09,4.76
  c0.87,1.13,1.73,2.27,2.66,3.36c0.87,1.01,1.78,1.99,3.01,2.58c0.62,0.29,1.27,0.57,1.93,0.69c1.81,0.31,3.63,0.37,5.46,0.2
  c2.34-0.23,4.69-0.3,7.03-0.13c3.46,0.25,6.13,1.86,7.84,4.93c0.93,1.66,1.63,3.42,2.3,5.2c0.75,1.98,1.43,3.98,2.52,5.81
  c0.82,1.38,1.93,2.39,3.42,3.03c1.71,0.73,3.5,1.16,5.31,1.55c2.04,0.45,4.1,0.89,6.06,1.62c2.43,0.91,4.41,2.39,5.49,4.84
  c0.54,1.22,0.92,2.49,1.06,3.83c0.28,2.65,0.54,5.3,0.85,7.95c0.15,1.32,0.47,2.61,1.07,3.82c0.42,0.85,1,1.57,1.74,2.18
  c1.51,1.25,3.21,2.2,4.92,3.13c1.69,0.92,3.37,1.84,4.94,2.96c1.45,1.03,2.72,2.22,3.47,3.86c0.34,0.73,0.59,1.51,0.77,2.29
  c0.45,1.99,0.14,3.98-0.2,5.95c-0.34,1.97-0.76,3.92-1.06,5.89c-0.2,1.36-0.34,2.74-0.15,4.13c0.16,1.16,0.6,2.17,1.23,3.13
  c1.11,1.68,2.51,3.1,3.9,4.53c1.41,1.45,2.82,2.9,4.02,4.52c0.87,1.17,1.57,2.44,1.92,3.88c0.36,1.47,0.33,2.94,0.05,4.41
  c-0.43,2.2-1.45,4.17-2.42,6.17c-0.86,1.74-1.7,3.5-2.48,5.27c-0.32,0.71-0.51,1.49-0.71,2.25c-0.4,1.61-0.14,3.17,0.37,4.73
  c0.65,1.98,1.64,3.79,2.52,5.66c0.73,1.56,1.49,3.11,2.12,4.71c1.55,3.98,0.9,7.61-1.82,10.92c-1.63,1.99-3.48,3.76-5.21,5.65
  c-1,1.09-1.94,2.22-2.68,3.51c-0.64,1.11-0.89,2.3-0.88,3.55c0.01,1.68,0.23,3.34,0.57,5c0.46,2.27,0.94,4.54,1.1,6.86
  c0.27,3.8-1.16,6.82-4.27,9.04c-1.87,1.33-3.89,2.41-5.9,3.5c-1.45,0.79-2.86,1.65-4.14,2.7c-1.21,0.99-1.9,2.29-2.31,3.77
  c-0.75,2.71-0.76,5.52-1.06,8.28c-0.21,1.99-0.5,3.95-1.36,5.8c-0.95,2.04-2.53,3.44-4.54,4.35c-1.55,0.7-3.19,1.13-4.85,1.51
  c-1.92,0.44-3.85,0.84-5.74,1.41c-1.43,0.43-2.84,0.98-3.93,2.11c-0.9,0.94-1.55,2.05-2.04,3.23c-0.94,2.22-1.76,4.49-2.68,6.72
  c-0.57,1.39-1.22,2.74-2.15,3.95c-1.4,1.81-3.21,2.92-5.47,3.37c-2.63,0.52-5.27,0.3-7.9,0.16c-1.75-0.09-3.48-0.21-5.24,0.03
  c-1.28,0.17-2.49,0.45-3.51,1.23c-0.8,0.61-1.54,1.31-2.21,2.06c-0.9,1.01-1.69,2.11-2.54,3.17c-1.13,1.4-2.21,2.84-3.41,4.17
  c-1.39,1.54-3.04,2.76-5.12,3.2c-0.81,0.17-1.66,0.21-2.49,0.23c-2.11,0.05-4.08-0.64-6.05-1.25c-2.07-0.64-4.11-1.36-6.19-1.97
  c-0.84-0.24-1.74-0.31-2.62-0.39c-1.37-0.13-2.63,0.3-3.84,0.88c-1.61,0.77-3.06,1.77-4.51,2.8c-1.97,1.4-3.93,2.81-6.11,3.86
  c-1.71,0.82-3.51,1.35-5.43,1.16c-1.66-0.16-3.21-0.71-4.68-1.5c-2.15-1.16-4.12-2.6-6.12-4c-1.52-1.06-3.06-2.08-4.8-2.73
  c-1.42-0.53-2.85-0.58-4.35-0.29c-1.48,0.29-2.92,0.69-4.35,1.16c-1.26,0.41-2.5,0.88-3.77,1.27c-2.06,0.62-4.13,1.15-6.32,0.99
  c-1.47-0.1-2.78-0.57-4.03-1.32c-1.5-0.91-2.67-2.19-3.76-3.52c-1.16-1.41-2.24-2.88-3.36-4.32c-1.02-1.3-2.08-2.56-3.41-3.57
  c-1.03-0.78-2.19-1.14-3.47-1.32c-1.51-0.21-3-0.23-4.5-0.15c-1.11,0.06-2.22,0.17-3.33,0.2c-1.16,0.03-2.32,0.05-3.48,0
  c-3.61-0.14-6.44-1.66-8.28-4.83c-0.93-1.6-1.6-3.32-2.27-5.04c-0.63-1.63-1.23-3.27-1.96-4.85c-0.67-1.46-1.5-2.87-2.97-3.67
  c-0.94-0.51-1.94-0.99-2.97-1.28c-2.28-0.63-4.6-1.07-6.89-1.68c-2-0.53-3.97-1.14-5.64-2.47c-1.58-1.27-2.57-2.91-3.09-4.83
  c-0.51-1.91-0.73-3.87-0.86-5.84c-0.12-1.72-0.28-3.45-0.56-5.15c-0.39-2.36-1.38-4.41-3.49-5.76c-0.83-0.53-1.64-1.1-2.48-1.6
  c-1.62-0.94-3.27-1.82-4.88-2.77c-1.25-0.74-2.46-1.55-3.48-2.61c-1.59-1.64-2.5-3.57-2.6-5.87c-0.08-1.81,0.14-3.59,0.47-5.37
  c0.39-2.11,0.81-4.21,1.09-6.33c0.21-1.59,0.23-3.21-0.43-4.74c-0.52-1.19-1.25-2.24-2.11-3.19c-1.42-1.57-2.93-3.07-4.35-4.63
  c-1.33-1.46-2.65-2.93-3.5-4.74c-0.76-1.62-1.18-3.31-0.93-5.09c0.23-1.61,0.7-3.17,1.41-4.65c1.01-2.11,2.01-4.22,3.03-6.32
  c0.64-1.31,1.15-2.68,1.44-4.1c0.28-1.39,0.12-2.78-0.31-4.16c-0.74-2.34-1.95-4.46-2.97-6.67c-0.68-1.47-1.38-2.94-1.93-4.47
  c-0.37-1.01-0.6-2.09-0.69-3.16c-0.2-2.2,0.53-4.18,1.72-6c1.17-1.78,2.64-3.31,4.11-4.84c0.92-0.96,1.86-1.91,2.74-2.9
  c1-1.13,1.95-2.3,2.44-3.75c0.22-0.65,0.37-1.34,0.41-2.02c0.1-1.63-0.13-3.24-0.43-4.85c-0.41-2.2-0.81-4.41-1.12-6.63
  c-0.22-1.52-0.19-3.06,0.21-4.56c0.39-1.45,1.14-2.72,2.18-3.81c1.43-1.5,3.19-2.54,4.96-3.56c1.71-0.98,3.44-1.91,5.12-2.94
  c1.09-0.67,2.12-1.44,2.88-2.51c0.58-0.81,0.97-1.71,1.22-2.67c0.6-2.35,0.68-4.78,0.91-7.18c0.21-2.13,0.43-4.26,1.25-6.28
  c0.93-2.31,2.59-3.87,4.81-4.86c1.51-0.67,3.1-1.09,4.72-1.47c2.11-0.49,4.21-1.01,6.29-1.61c1.4-0.4,2.71-1.06,3.7-2.2
  c0.81-0.94,1.42-2.02,1.89-3.16c0.93-2.25,1.78-4.53,2.71-6.78c0.48-1.16,1.03-2.29,1.76-3.32c1.43-1.99,3.3-3.24,5.72-3.75
  C63.22,19.18,64.82,19.31,66.22,19.23z M190.38,234.3c0-0.01,0-0.02,0-0.03c0.52,0,1.04,0.02,1.56,0c1.11-0.04,2.22-0.2,3.27-0.59
  c1.38-0.5,2.34-1.51,3.09-2.74c1.11-1.82,1.79-3.83,2.55-5.8c0.69-1.82,1.39-3.63,2.36-5.33c1.2-2.09,2.86-3.59,5.11-4.48
  c1.4-0.55,2.83-1,4.3-1.32c1.25-0.27,2.5-0.54,3.74-0.86c1.55-0.4,3.11-0.78,4.52-1.57c1.03-0.58,1.86-1.35,2.41-2.43
  c0.53-1.03,0.82-2.13,0.99-3.24c0.27-1.73,0.43-3.48,0.56-5.22c0.11-1.53,0.25-3.05,0.61-4.54c0.24-1,0.56-2,0.98-2.93
  c0.85-1.92,2.4-3.25,4.12-4.34c1.85-1.17,3.79-2.2,5.68-3.3c1.25-0.73,2.51-1.46,3.62-2.42c1.47-1.28,2.3-2.84,2.35-4.83
  c0.04-1.59-0.2-3.15-0.48-4.7c-0.23-1.28-0.49-2.56-0.72-3.85c-0.16-0.92-0.32-1.85-0.43-2.78c-0.27-2.17-0.02-4.28,0.95-6.27
  c0.7-1.43,1.64-2.69,2.7-3.86c0.91-1,1.88-1.96,2.79-2.96c1.15-1.28,2.31-2.56,3.4-3.9c0.77-0.94,1.27-2.06,1.5-3.26
  c0.23-1.19,0.05-2.37-0.34-3.5c-0.4-1.19-0.89-2.35-1.42-3.48c-0.95-2.05-1.97-4.08-2.93-6.13c-0.65-1.39-1.08-2.86-1.25-4.38
  c-0.23-2.03,0.14-4,0.93-5.86c0.91-2.15,1.95-4.25,2.97-6.35c0.83-1.71,1.66-3.42,1.99-5.3c0.14-0.76,0.13-1.59,0-2.35
  c-0.26-1.46-1-2.73-1.97-3.83c-1.15-1.32-2.36-2.59-3.53-3.89c-1.2-1.34-2.44-2.65-3.56-4.05c-1.76-2.21-2.49-4.78-2.31-7.6
  c0.03-0.51,0.13-1.03,0.22-1.54c0.34-1.97,0.69-3.93,1.02-5.9c0.28-1.66,0.56-3.31,0.44-5c-0.1-1.46-0.63-2.72-1.62-3.79
  c-1.23-1.32-2.77-2.2-4.3-3.09c-1.83-1.06-3.7-2.07-5.49-3.18c-1.24-0.76-2.36-1.69-3.3-2.83c-1.35-1.64-1.9-3.62-2.23-5.64
  c-0.31-1.87-0.46-3.77-0.59-5.66c-0.12-1.76-0.36-3.48-0.87-5.17c-0.28-0.93-0.74-1.76-1.44-2.46c-0.89-0.9-1.98-1.48-3.17-1.84
  c-1.84-0.55-3.7-1-5.57-1.44c-1.68-0.39-3.35-0.79-4.96-1.42c-1.96-0.76-3.63-1.89-4.8-3.66c-0.77-1.15-1.41-2.37-1.93-3.65
  c-0.9-2.24-1.76-4.49-2.7-6.71c-0.34-0.81-0.8-1.58-1.3-2.31c-0.88-1.3-2.12-2.12-3.66-2.45c-1.83-0.39-3.68-0.39-5.53-0.24
  c-2.78,0.22-5.56,0.4-8.34-0.04c-0.96-0.15-1.87-0.42-2.74-0.83c-1.55-0.72-2.79-1.87-3.87-3.14c-1.43-1.69-2.75-3.47-4.14-5.2
  c-1.05-1.31-2.09-2.63-3.47-3.61c-0.9-0.64-1.89-1.08-2.99-1.18c-1.24-0.11-2.47,0.03-3.66,0.4c-2.59,0.8-5.16,1.64-7.75,2.43
  c-0.87,0.27-1.75,0.53-2.65,0.66c-2.8,0.4-5.47-0.03-7.89-1.55c-1.77-1.11-3.51-2.28-5.24-3.46c-1.47-1-2.93-2-4.54-2.76
  c-1.13-0.54-2.33-0.84-3.58-0.79c-1.51,0.06-2.91,0.56-4.18,1.35c-1.65,1.03-3.27,2.11-4.87,3.21c-1.59,1.08-3.15,2.2-4.9,3.02
  c-1.12,0.52-2.27,0.93-3.51,1.05c-2.44,0.25-4.75-0.32-7.04-1.05c-2.6-0.82-5.14-1.87-7.84-2.38c-2.15-0.4-4.11-0.09-5.84,1.33
  c-1.13,0.93-2.07,2.04-2.98,3.18c-1.42,1.77-2.76,3.6-4.22,5.32c-0.73,0.86-1.61,1.6-2.49,2.3c-1.62,1.3-3.58,1.78-5.58,1.89
  c-1.92,0.11-3.86,0.08-5.77-0.05c-2.12-0.15-4.24-0.34-6.34,0.11c-0.67,0.14-1.34,0.34-1.95,0.65c-1.21,0.6-2.01,1.64-2.66,2.79
  c-0.91,1.61-1.55,3.35-2.2,5.08c-0.71,1.89-1.41,3.77-2.41,5.54c-1.11,1.97-2.6,3.52-4.71,4.41c-0.97,0.41-1.97,0.78-2.99,1.06
  c-1.69,0.46-3.39,0.84-5.1,1.24c-1.7,0.4-3.4,0.81-4.96,1.65c-1.23,0.67-2.16,1.63-2.67,2.95c-0.26,0.69-0.52,1.4-0.63,2.12
  c-0.25,1.63-0.44,3.28-0.59,4.92c-0.19,2.09-0.35,4.17-0.87,6.22c-0.51,1.99-1.48,3.7-3.02,5.05c-1.32,1.16-2.79,2.1-4.32,2.96
  c-1.82,1.03-3.64,2.07-5.43,3.14c-1.09,0.65-2.05,1.47-2.76,2.53c-0.98,1.46-1.2,3.12-1.02,4.82c0.14,1.35,0.4,2.68,0.63,4.02
  c0.41,2.33,0.96,4.64,1.02,7.02c0.04,1.34-0.05,2.67-0.48,3.95c-0.54,1.61-1.49,3-2.58,4.28c-1.06,1.23-2.21,2.38-3.3,3.59
  c-1.17,1.3-2.38,2.57-3.46,3.94c-1,1.27-1.65,2.73-1.61,4.43c0.03,1.2,0.26,2.37,0.74,3.45c0.96,2.16,2.02,4.27,3.03,6.4
  c0.99,2.09,1.92,4.2,2.22,6.54c0.16,1.23,0.01,2.43-0.23,3.6c-0.33,1.62-1.01,3.14-1.72,4.63c-0.97,2.04-1.97,4.08-2.93,6.12
  c-0.47,1-0.84,2.05-1.02,3.15c-0.35,2.1,0.23,3.98,1.53,5.6c1.09,1.36,2.27,2.66,3.47,3.93c1.45,1.54,2.93,3.05,4.2,4.76
  c1.66,2.25,2.37,4.74,2.09,7.51c-0.17,1.69-0.43,3.37-0.69,5.05c-0.14,0.95-0.37,1.89-0.54,2.83c-0.25,1.41-0.51,2.82-0.41,4.26
  c0.11,1.49,0.54,2.88,1.62,3.95c0.67,0.67,1.41,1.32,2.21,1.82c1.71,1.07,3.48,2.04,5.24,3.03c1.55,0.88,3.04,1.83,4.4,2.97
  c2.01,1.7,2.97,3.95,3.42,6.45c0.27,1.48,0.38,2.98,0.54,4.48c0.14,1.3,0.18,2.61,0.38,3.9c0.18,1.14,0.43,2.28,0.8,3.37
  c0.65,1.92,2.14,3,3.96,3.71c1.89,0.73,3.87,1.14,5.84,1.58c1.98,0.44,3.93,0.93,5.8,1.7c1.79,0.74,3.17,1.99,4.26,3.54
  c0.84,1.19,1.47,2.5,2,3.86c0.73,1.88,1.44,3.77,2.21,5.63c0.5,1.21,1.07,2.38,1.9,3.41c0.98,1.2,2.23,1.88,3.75,2.16
  c1.79,0.33,3.59,0.25,5.39,0.17c2-0.09,4-0.21,5.99-0.18c1.91,0.03,3.78,0.37,5.46,1.37c1.27,0.75,2.34,1.73,3.3,2.83
  c1,1.15,1.93,2.37,2.86,3.58c1.18,1.52,2.33,3.06,3.71,4.41c1.66,1.62,3.63,2.32,5.94,1.98c1.51-0.22,2.98-0.67,4.43-1.14
  c2.31-0.76,4.59-1.63,6.98-2.15c2.57-0.55,5.05-0.37,7.43,0.76c1.86,0.88,3.53,2.05,5.2,3.22c1.83,1.29,3.64,2.61,5.68,3.58
  c1.29,0.61,2.64,0.92,4.08,0.78c1.36-0.13,2.6-0.61,3.73-1.31c1.53-0.94,3.02-1.94,4.51-2.95c1.73-1.18,3.43-2.39,5.33-3.31
  c2.26-1.09,4.64-1.33,7.07-0.86c2.01,0.38,3.94,1.06,5.87,1.73c1.77,0.61,3.54,1.2,5.38,1.56c2.53,0.49,4.67-0.14,6.51-1.92
  c1.35-1.31,2.48-2.8,3.61-4.29c1.46-1.93,2.91-3.86,4.76-5.44c1.32-1.13,2.83-1.84,4.54-2.16c1.79-0.33,3.59-0.32,5.39-0.26
  C187.12,234.09,188.75,234.21,190.38,234.3z"
      />
      <path
        d="M190.38,234.3c-1.63-0.09-3.25-0.21-4.88-0.27c-1.8-0.07-3.6-0.08-5.39,0.26c-1.71,0.32-3.22,1.03-4.54,2.16
  c-1.86,1.58-3.31,3.51-4.76,5.44c-1.13,1.49-2.26,2.98-3.61,4.29c-1.83,1.79-3.98,2.42-6.51,1.92c-1.85-0.36-3.62-0.95-5.38-1.56
  c-1.93-0.67-3.86-1.34-5.87-1.73c-2.44-0.47-4.81-0.23-7.07,0.86c-1.9,0.92-3.6,2.13-5.33,3.31c-1.48,1.02-2.97,2.02-4.51,2.95
  c-1.14,0.7-2.37,1.18-3.73,1.31c-1.45,0.14-2.79-0.16-4.08-0.78c-2.03-0.97-3.84-2.29-5.68-3.58c-1.67-1.17-3.35-2.34-5.2-3.22
  c-2.39-1.13-4.86-1.32-7.43-0.76c-2.39,0.52-4.67,1.39-6.98,2.15c-1.45,0.48-2.91,0.92-4.43,1.14c-2.31,0.34-4.28-0.36-5.94-1.98
  c-1.38-1.35-2.54-2.88-3.71-4.41c-0.93-1.21-1.86-2.43-2.86-3.58c-0.96-1.1-2.03-2.08-3.3-2.83c-1.69-0.99-3.55-1.34-5.46-1.37
  c-2-0.03-4,0.09-5.99,0.18c-1.8,0.08-3.6,0.15-5.39-0.17c-1.52-0.28-2.77-0.96-3.75-2.16c-0.84-1.03-1.41-2.2-1.9-3.41
  c-0.76-1.86-1.47-3.75-2.21-5.63c-0.53-1.36-1.16-2.67-2-3.86c-1.09-1.55-2.47-2.8-4.26-3.54c-1.87-0.78-3.82-1.27-5.8-1.7
  c-1.97-0.43-3.95-0.84-5.84-1.58c-1.82-0.71-3.31-1.79-3.96-3.71c-0.37-1.09-0.62-2.23-0.8-3.37c-0.2-1.29-0.24-2.6-0.38-3.9
  c-0.16-1.5-0.27-3-0.54-4.48c-0.45-2.5-1.41-4.76-3.42-6.45c-1.36-1.15-2.86-2.1-4.4-2.97c-1.75-1-3.53-1.97-5.24-3.03
  c-0.81-0.5-1.54-1.15-2.21-1.82c-1.08-1.08-1.51-2.46-1.62-3.95c-0.1-1.44,0.16-2.85,0.41-4.26c0.17-0.95,0.39-1.88,0.54-2.83
  c0.25-1.68,0.51-3.36,0.69-5.05c0.28-2.76-0.42-5.26-2.09-7.51c-1.27-1.71-2.75-3.22-4.2-4.76c-1.2-1.27-2.38-2.57-3.47-3.93
  c-1.31-1.63-1.88-3.5-1.53-5.6c0.18-1.1,0.55-2.15,1.02-3.15c0.96-2.05,1.96-4.08,2.93-6.12c0.71-1.49,1.39-3.01,1.72-4.63
  c0.24-1.17,0.38-2.37,0.23-3.6c-0.3-2.34-1.23-4.45-2.22-6.54c-1.01-2.13-2.07-4.25-3.03-6.4c-0.48-1.08-0.71-2.25-0.74-3.45
  c-0.04-1.69,0.6-3.15,1.61-4.43c1.08-1.37,2.29-2.64,3.46-3.94c1.09-1.21,2.24-2.36,3.3-3.59c1.09-1.27,2.04-2.66,2.58-4.28
  c0.43-1.28,0.51-2.61,0.48-3.95c-0.06-2.38-0.61-4.69-1.02-7.02c-0.24-1.33-0.49-2.67-0.63-4.02c-0.18-1.7,0.03-3.36,1.02-4.82
  c0.71-1.06,1.68-1.88,2.76-2.53c1.8-1.07,3.61-2.11,5.43-3.14c1.52-0.86,3-1.8,4.32-2.96c1.54-1.36,2.51-3.06,3.02-5.05
  c0.53-2.04,0.69-4.13,0.87-6.22c0.15-1.65,0.34-3.29,0.59-4.92c0.11-0.72,0.37-1.43,0.63-2.12c0.51-1.32,1.43-2.28,2.67-2.95
  c1.56-0.84,3.26-1.25,4.96-1.65c1.7-0.4,3.41-0.78,5.1-1.24c1.02-0.28,2.01-0.65,2.99-1.06c2.1-0.89,3.59-2.44,4.71-4.41
  c1-1.77,1.7-3.65,2.41-5.54c0.65-1.73,1.29-3.46,2.2-5.08c0.65-1.15,1.45-2.2,2.66-2.79c0.61-0.3,1.28-0.5,1.95-0.65
  c2.1-0.45,4.22-0.25,6.34-0.11c1.92,0.13,3.86,0.16,5.77,0.05c2.01-0.11,3.96-0.59,5.58-1.89c0.88-0.71,1.76-1.45,2.49-2.3
  c1.46-1.73,2.81-3.55,4.22-5.32c0.91-1.13,1.85-2.24,2.98-3.18c1.73-1.43,3.69-1.74,5.84-1.33c2.7,0.51,5.23,1.56,7.84,2.38
  c2.29,0.72,4.6,1.3,7.04,1.05c1.25-0.13,2.4-0.53,3.51-1.05c1.75-0.82,3.31-1.93,4.9-3.02c1.6-1.1,3.22-2.18,4.87-3.21
  c1.27-0.79,2.67-1.29,4.18-1.35c1.25-0.05,2.45,0.25,3.58,0.79c1.61,0.76,3.07,1.77,4.54,2.76c1.73,1.18,3.46,2.35,5.24,3.46
  c2.42,1.51,5.09,1.95,7.89,1.55c0.9-0.13,1.78-0.39,2.65-0.66c2.59-0.8,5.16-1.64,7.75-2.43c1.19-0.37,2.42-0.51,3.66-0.4
  c1.11,0.1,2.09,0.54,2.99,1.18c1.39,0.99,2.43,2.3,3.47,3.61c1.39,1.73,2.71,3.51,4.14,5.2c1.08,1.28,2.32,2.42,3.87,3.14
  c0.87,0.41,1.78,0.68,2.74,0.83c2.78,0.45,5.56,0.27,8.34,0.04c1.85-0.15,3.7-0.15,5.53,0.24c1.53,0.33,2.78,1.15,3.66,2.45
  c0.49,0.73,0.95,1.5,1.3,2.31c0.94,2.22,1.8,4.48,2.7,6.71c0.52,1.28,1.17,2.5,1.93,3.65c1.18,1.77,2.85,2.9,4.8,3.66
  c1.61,0.63,3.28,1.03,4.96,1.42c1.87,0.44,3.73,0.89,5.57,1.44c1.18,0.35,2.27,0.93,3.17,1.84c0.69,0.7,1.16,1.53,1.44,2.46
  c0.51,1.68,0.75,3.41,0.87,5.17c0.13,1.89,0.29,3.79,0.59,5.66c0.33,2.02,0.88,3.99,2.23,5.64c0.94,1.14,2.06,2.07,3.3,2.83
  c1.8,1.11,3.67,2.11,5.49,3.18c1.53,0.89,3.07,1.77,4.3,3.09c1,1.07,1.52,2.33,1.62,3.79c0.12,1.69-0.16,3.35-0.44,5
  c-0.33,1.97-0.68,3.93-1.02,5.9c-0.09,0.51-0.19,1.02-0.22,1.54c-0.18,2.82,0.55,5.39,2.31,7.6c1.12,1.4,2.36,2.71,3.56,4.05
  c1.17,1.3,2.37,2.57,3.53,3.89c0.97,1.11,1.71,2.38,1.97,3.83c0.14,0.76,0.14,1.59,0,2.35c-0.33,1.88-1.16,3.59-1.99,5.3
  c-1.03,2.1-2.06,4.2-2.97,6.35c-0.79,1.86-1.15,3.83-0.93,5.86c0.17,1.52,0.6,2.99,1.25,4.38c0.96,2.05,1.98,4.07,2.93,6.13
  c0.53,1.14,1.02,2.3,1.42,3.48c0.38,1.12,0.57,2.31,0.34,3.5c-0.23,1.2-0.73,2.32-1.5,3.26c-1.09,1.33-2.24,2.62-3.4,3.9
  c-0.91,1.01-1.87,1.96-2.79,2.96c-1.07,1.17-2.01,2.43-2.7,3.86c-0.97,1.99-1.21,4.1-0.95,6.27c0.11,0.93,0.27,1.85,0.43,2.78
  c0.23,1.28,0.49,2.56,0.72,3.85c0.28,1.56,0.52,3.11,0.48,4.7c-0.05,1.98-0.88,3.55-2.35,4.83c-1.1,0.96-2.36,1.68-3.62,2.42
  c-1.89,1.11-3.83,2.13-5.68,3.3c-1.72,1.09-3.27,2.42-4.12,4.34c-0.42,0.94-0.73,1.93-0.98,2.93c-0.36,1.49-0.51,3.01-0.61,4.54
  c-0.12,1.75-0.29,3.5-0.56,5.22c-0.17,1.11-0.46,2.21-0.99,3.24c-0.55,1.07-1.38,1.84-2.41,2.43c-1.41,0.8-2.97,1.18-4.52,1.57
  c-1.24,0.32-2.49,0.59-3.74,0.86c-1.47,0.32-2.9,0.76-4.3,1.32c-2.25,0.89-3.91,2.39-5.11,4.48c-0.97,1.7-1.67,3.51-2.36,5.33
  c-0.75,1.98-1.44,3.98-2.55,5.8c-0.75,1.23-1.72,2.24-3.09,2.74c-1.05,0.38-2.15,0.54-3.27,0.59c-0.52,0.02-1.04,0-1.56,0
  C190.38,234.28,190.38,234.29,190.38,234.3z M128.19,238.55c3.9-0.13,7.92-0.19,11.92-0.68c1.08-0.13,2.16-0.25,3.23-0.4
  c1.15-0.15,2.3-0.3,3.44-0.51c1.84-0.34,3.67-0.71,5.5-1.11c1.54-0.34,3.08-0.67,4.59-1.11c2.27-0.66,4.51-1.41,6.76-2.1
  c2.71-0.83,5.33-1.91,7.93-3.02c3.6-1.53,7.11-3.27,10.52-5.19c2.58-1.45,5.11-2.97,7.54-4.65c2.31-1.6,4.59-3.24,6.8-4.97
  c2.04-1.59,4.02-3.26,5.93-5c3.12-2.84,6.08-5.84,8.85-9.03c2.75-3.17,5.37-6.44,7.7-9.92c1.64-2.45,3.24-4.94,4.75-7.48
  c2.08-3.49,3.92-7.11,5.51-10.85c1.19-2.8,2.26-5.66,3.31-8.53c0.66-1.82,1.2-3.69,1.75-5.56c0.4-1.35,0.73-2.71,1.06-4.08
  c0.38-1.6,0.75-3.21,1.08-4.82c0.24-1.13,0.41-2.28,0.6-3.42c0.15-0.87,0.32-1.75,0.43-2.63c0.15-1.12,0.23-2.26,0.39-3.38
  c0.45-3.18,0.63-6.39,0.72-9.6c0.11-4.4-0.01-8.79-0.49-13.17c-0.22-1.96-0.39-3.93-0.69-5.88c-0.35-2.21-0.79-4.41-1.24-6.61
  c-0.3-1.49-0.65-2.98-1.03-4.46c-0.36-1.43-0.77-2.85-1.19-4.27c-0.37-1.25-0.72-2.51-1.17-3.73c-0.77-2.13-1.54-4.26-2.41-6.35
  c-1.07-2.54-2.23-5.04-3.39-7.54c-0.27-0.59-0.75-1.09-1.05-1.67c-1.83-3.47-3.82-6.84-6-10.09c-3.17-4.73-6.67-9.22-10.54-13.4
  c-3.79-4.1-7.85-7.89-12.21-11.39c-3.7-2.96-7.56-5.69-11.61-8.14c-2.19-1.32-4.44-2.56-6.7-3.76c-3.24-1.72-6.57-3.25-10-4.55
  c-1.79-0.68-3.59-1.37-5.41-1.97c-2.29-0.75-4.6-1.45-6.92-2.12c-1.32-0.38-2.67-0.68-4.01-0.99c-0.96-0.23-1.92-0.45-2.89-0.62
  c-1.87-0.33-3.74-0.63-5.62-0.92c-1.17-0.18-2.34-0.34-3.52-0.46c-1.4-0.14-2.8-0.23-4.2-0.36c-3.22-0.31-6.46-0.35-9.69-0.29
  c-2.64,0.04-5.28,0.09-7.91,0.38c-2.01,0.23-4.02,0.4-6.03,0.66c-1.39,0.18-2.78,0.43-4.16,0.71c-2.41,0.48-4.84,0.92-7.23,1.52
  c-2.41,0.6-4.79,1.34-7.16,2.07c-3.27,1-6.46,2.24-9.6,3.6c-3.53,1.52-6.96,3.22-10.31,5.1c-4.07,2.28-7.97,4.81-11.72,7.58
  c-4.23,3.13-8.21,6.54-11.94,10.24c-3.15,3.13-6.11,6.43-8.87,9.91c-2.89,3.63-5.52,7.43-7.94,11.38c-1.62,2.65-3.14,5.35-4.47,8.15
  c-1.07,2.24-2.13,4.49-3.09,6.78c-0.87,2.09-1.6,4.23-2.39,6.36c-0.93,2.52-1.65,5.1-2.35,7.69c-0.37,1.35-0.69,2.72-0.98,4.09
  c-0.42,2-0.84,4-1.19,6.02c-0.28,1.6-0.47,3.22-0.64,4.84c-0.27,2.48-0.61,4.96-0.69,7.44c-0.13,4.51-0.29,9.03,0.18,13.54
  c0.2,1.94,0.31,3.89,0.54,5.82c0.22,1.81,0.52,3.61,0.82,5.41c0.27,1.58,0.57,3.15,0.89,4.72c0.26,1.3,0.53,2.61,0.88,3.89
  c0.57,2.06,1.19,4.11,1.83,6.15c0.65,2.06,1.37,4.11,2.15,6.13c1.33,3.42,2.82,6.77,4.47,10.05c2.03,4.06,4.34,7.96,6.87,11.71
  c3.31,4.91,6.99,9.53,11.05,13.85c4.8,5.11,10.03,9.75,15.71,13.87c4.33,3.15,8.86,5.97,13.6,8.45c3.07,1.61,6.21,3.08,9.43,4.4
  c3.26,1.33,6.57,2.52,9.94,3.52c1.44,0.43,2.88,0.86,4.33,1.23c1.41,0.37,2.83,0.69,4.25,0.99c1.61,0.34,3.23,0.66,4.86,0.93
  c1.48,0.25,2.98,0.44,4.47,0.61c2.3,0.26,4.61,0.56,6.92,0.71C123.38,238.47,125.72,238.48,128.19,238.55z"
      />
      <path
        d="M128.19,238.55c-2.47-0.07-4.82-0.08-7.16-0.24c-2.31-0.15-4.62-0.45-6.92-0.71c-1.49-0.17-2.99-0.36-4.47-0.61
  c-1.63-0.27-3.25-0.59-4.86-0.93c-1.42-0.3-2.84-0.62-4.25-0.99c-1.45-0.38-2.89-0.81-4.33-1.23c-3.37-1-6.68-2.19-9.94-3.52
  c-3.21-1.31-6.35-2.78-9.43-4.4c-4.74-2.48-9.27-5.31-13.6-8.45c-5.68-4.13-10.9-8.76-15.71-13.87c-4.06-4.32-7.74-8.95-11.05-13.85
  c-2.54-3.76-4.84-7.66-6.87-11.71c-1.64-3.28-3.14-6.62-4.47-10.05c-0.78-2.02-1.5-4.06-2.15-6.13c-0.64-2.04-1.26-4.09-1.83-6.15
  c-0.35-1.28-0.61-2.59-0.88-3.89c-0.32-1.57-0.62-3.14-0.89-4.72c-0.3-1.8-0.6-3.6-0.82-5.41c-0.23-1.93-0.34-3.88-0.54-5.82
  c-0.47-4.51-0.32-9.03-0.18-13.54c0.07-2.49,0.42-4.97,0.69-7.44c0.17-1.62,0.37-3.24,0.64-4.84c0.35-2.01,0.77-4.02,1.19-6.02
  c0.29-1.37,0.61-2.74,0.98-4.09c0.71-2.59,1.42-5.17,2.35-7.69c0.79-2.12,1.52-4.27,2.39-6.36c0.96-2.29,2.02-4.54,3.09-6.78
  c1.33-2.8,2.85-5.51,4.47-8.15c2.42-3.95,5.05-7.75,7.94-11.38c2.77-3.48,5.72-6.78,8.87-9.91c3.73-3.7,7.71-7.12,11.94-10.24
  c3.75-2.77,7.65-5.3,11.72-7.58c3.35-1.88,6.79-3.58,10.31-5.1c3.14-1.36,6.33-2.59,9.6-3.6c2.38-0.73,4.75-1.47,7.16-2.07
  c2.39-0.6,4.81-1.04,7.23-1.52c1.38-0.27,2.76-0.53,4.16-0.71c2-0.26,4.02-0.44,6.03-0.66c2.63-0.3,5.27-0.34,7.91-0.38
  c3.23-0.05,6.47-0.01,9.69,0.29c1.4,0.13,2.81,0.22,4.2,0.36c1.18,0.12,2.35,0.28,3.52,0.46c1.88,0.29,3.75,0.59,5.62,0.92
  c0.97,0.17,1.93,0.4,2.89,0.62c1.34,0.32,2.69,0.61,4.01,0.99c2.32,0.67,4.63,1.36,6.92,2.12c1.82,0.6,3.62,1.29,5.41,1.97
  c3.43,1.3,6.76,2.83,10,4.55c2.26,1.2,4.51,2.43,6.7,3.76c4.05,2.45,7.92,5.17,11.61,8.14c4.35,3.49,8.42,7.29,12.21,11.39
  c3.87,4.19,7.37,8.67,10.54,13.4c2.18,3.25,4.17,6.62,6,10.09c0.31,0.58,0.78,1.08,1.05,1.67c1.16,2.5,2.33,5,3.39,7.54
  c0.88,2.08,1.65,4.22,2.41,6.35c0.44,1.23,0.8,2.48,1.17,3.73c0.42,1.42,0.83,2.84,1.19,4.27c0.38,1.48,0.72,2.96,1.03,4.46
  c0.45,2.2,0.89,4.4,1.24,6.61c0.3,1.95,0.48,3.91,0.69,5.88c0.48,4.38,0.6,8.77,0.49,13.17c-0.08,3.21-0.27,6.41-0.72,9.6
  c-0.16,1.12-0.24,2.26-0.39,3.38c-0.12,0.88-0.28,1.75-0.43,2.63c-0.2,1.14-0.37,2.29-0.6,3.42c-0.33,1.61-0.7,3.22-1.08,4.82
  c-0.33,1.37-0.66,2.73-1.06,4.08c-0.55,1.86-1.09,3.73-1.75,5.56c-1.04,2.86-2.11,5.72-3.31,8.53c-1.59,3.74-3.43,7.36-5.51,10.85
  c-1.52,2.53-3.11,5.03-4.75,7.48c-2.34,3.48-4.95,6.75-7.7,9.92c-2.77,3.19-5.73,6.19-8.85,9.03c-1.91,1.74-3.9,3.41-5.93,5
  c-2.21,1.73-4.49,3.37-6.8,4.97c-2.43,1.69-4.96,3.21-7.54,4.65c-3.41,1.92-6.92,3.65-10.52,5.19c-2.6,1.11-5.22,2.18-7.93,3.02
  c-2.26,0.69-4.5,1.45-6.76,2.1c-1.51,0.44-3.05,0.78-4.59,1.11c-1.83,0.4-3.66,0.77-5.5,1.11c-1.14,0.21-2.29,0.35-3.44,0.51
  c-1.08,0.14-2.16,0.26-3.23,0.4C136.12,238.36,132.09,238.42,128.19,238.55z M224.53,80.48c-0.86,0.16-1.6,0.29-2.35,0.44
  c-1.21,0.23-2.42,0.47-3.63,0.7c-1.14,0.22-2.28,0.41-3.42,0.63c-1.31,0.25-2.61,0.51-3.91,0.77c-1.19,0.23-2.37,0.46-3.56,0.69
  c-1.14,0.22-2.27,0.44-3.41,0.65c-1.16,0.22-2.33,0.42-3.49,0.64c-1.64,0.32-3.28,0.66-4.93,0.97c-1.26,0.24-2.52,0.46-3.78,0.7
  c-1.14,0.22-2.27,0.45-3.41,0.66c-1.23,0.23-2.47,0.45-3.7,0.68c-1.24,0.23-2.47,0.48-3.7,0.72c-1.14,0.22-2.28,0.42-3.41,0.64
  c-1.26,0.24-2.51,0.5-3.77,0.74c-1.14,0.22-2.28,0.42-3.42,0.64c-1.18,0.23-2.36,0.48-3.55,0.71c-1.26,0.24-2.52,0.47-3.78,0.71
  c-1.11,0.21-2.22,0.44-3.34,0.64c-1.21,0.23-2.43,0.43-3.64,0.66c-1.65,0.32-3.29,0.66-4.93,0.97c-1.23,0.24-2.47,0.45-3.7,0.68
  c-1.24,0.23-2.47,0.48-3.7,0.72c-1.14,0.22-2.28,0.42-3.41,0.63c-1.28,0.25-2.56,0.5-3.84,0.75c-1.19,0.23-2.37,0.46-3.56,0.69
  c-1.14,0.22-2.27,0.44-3.41,0.65c-1.24,0.23-2.47,0.46-3.71,0.7c-1.11,0.21-2.22,0.44-3.34,0.65c-1.21,0.23-2.43,0.43-3.63,0.66
  c-1.65,0.32-3.29,0.66-4.93,0.97c-1.23,0.24-2.47,0.45-3.7,0.68c-1.26,0.24-2.52,0.49-3.78,0.73c-1.11,0.21-2.23,0.41-3.34,0.62
  c-1.31,0.25-2.61,0.52-3.91,0.77c-1.09,0.21-2.18,0.4-3.27,0.61c-1.26,0.24-2.51,0.5-3.77,0.74c-1.16,0.22-2.33,0.44-3.49,0.66
  c-1.14,0.22-2.27,0.45-3.41,0.67c-1.21,0.23-2.43,0.43-3.64,0.66c-1.65,0.32-3.29,0.66-4.93,0.98c-1.74,0.34-3.49,0.65-5.23,0.98
  c-1.57,0.3-3.14,0.62-4.72,0.91c-1.23,0.23-2.47,0.44-3.71,0.67c-1.65,0.32-3.28,0.66-4.93,0.98c-1.77,0.34-3.54,0.66-5.3,0.99
  c-1.55,0.3-3.1,0.61-4.65,0.9c-1.26,0.24-2.52,0.45-3.78,0.69c-1.65,0.32-3.29,0.66-4.93,0.98c-1.74,0.33-3.49,0.64-5.23,0.98
  c-2.08,0.4-4.16,0.82-6.24,1.22c-1.65,0.31-3.3,0.6-4.95,0.91c-0.6,0.11-1.21,0.22-1.81,0.38c-0.61,0.16-0.62,0.2-0.65,0.81
  c-0.05,0.91-0.03,1.83-0.11,2.74c-0.27,2.86-0.26,5.72-0.13,8.59c0.11,2.42,0.29,4.83,0.47,7.24c0.09,1.16,0.22,2.31,0.38,3.46
  c0.2,1.39,0.43,2.78,0.67,4.16c0.3,1.7,0.58,3.4,0.97,5.08c0.45,1.94,0.98,3.87,1.51,5.79c0.45,1.64,0.92,3.27,1.46,4.88
  c1.18,3.5,2.53,6.93,4.05,10.29c0.36,0.79,0.72,1.57,1.1,2.34c0.13,0.26,0.24,0.56,0.64,0.48c0.48-0.1,0.97-0.17,1.45-0.26
  c1.3-0.26,2.61-0.52,3.91-0.78c1.07-0.2,2.14-0.38,3.2-0.59c1.33-0.25,2.66-0.52,3.99-0.78c1.09-0.21,2.18-0.42,3.27-0.63
  c1.67-0.33,3.33-0.66,5-0.98c1.26-0.24,2.52-0.47,3.78-0.71c1.11-0.21,2.22-0.44,3.34-0.65c1.21-0.23,2.42-0.43,3.64-0.66
  c1.28-0.24,2.56-0.51,3.85-0.75c1.09-0.21,2.18-0.39,3.27-0.6c1.31-0.25,2.61-0.52,3.91-0.78c1.09-0.21,2.18-0.4,3.27-0.6
  c1.23-0.24,2.46-0.5,3.69-0.73c1.16-0.22,2.33-0.42,3.49-0.65c1.67-0.32,3.33-0.66,5-0.99c1.26-0.24,2.52-0.47,3.78-0.7
  c1.16-0.22,2.32-0.45,3.48-0.67c1.19-0.22,2.37-0.43,3.56-0.66c1.21-0.23,2.42-0.48,3.63-0.71c1.14-0.22,2.28-0.4,3.42-0.62
  c1.33-0.25,2.66-0.53,3.99-0.79c1.07-0.21,2.13-0.39,3.2-0.59c1.23-0.24,2.46-0.5,3.69-0.73c1.16-0.22,2.33-0.42,3.49-0.65
  c1.67-0.32,3.33-0.67,5-0.99c1.26-0.24,2.52-0.46,3.78-0.7c1.11-0.21,2.22-0.44,3.34-0.65c1.21-0.23,2.43-0.43,3.64-0.65
  c1.31-0.25,2.61-0.52,3.92-0.77c1.07-0.2,2.13-0.38,3.2-0.58c1.35-0.26,2.7-0.54,4.06-0.8c1.04-0.2,2.09-0.38,3.13-0.58
  c1.23-0.24,2.46-0.5,3.69-0.74c1.16-0.22,2.33-0.42,3.49-0.65c1.67-0.32,3.33-0.67,5-0.99c1.31-0.25,2.62-0.49,3.92-0.74
  c1.19-0.22,2.37-0.44,3.56-0.66c1.11-0.21,2.23-0.41,3.34-0.62c1.23-0.24,2.46-0.5,3.7-0.73c1.64-0.32,3.29-0.62,4.94-0.93
  c1.24-0.23,2.47-0.48,3.7-0.71c1.11-0.21,2.23-0.4,3.34-0.62c1.33-0.25,2.66-0.52,3.99-0.78c1.16-0.23,2.32-0.46,3.48-0.69
  c1.14-0.22,2.27-0.43,3.41-0.65c1.24-0.23,2.47-0.46,3.71-0.7c1.11-0.21,2.22-0.43,3.34-0.64c1.21-0.23,2.42-0.43,3.63-0.66
  c1.65-0.32,3.29-0.65,4.93-0.97c1.23-0.24,2.47-0.45,3.7-0.68c1.24-0.23,2.47-0.48,3.7-0.72c1.62-0.31,3.24-0.6,4.86-0.91
  c1.67-0.32,3.34-0.65,5.01-0.97c0.58-0.11,0.65-0.13,0.71-0.74c0.08-0.71,0.13-1.43,0.16-2.14c0.13-3.21,0.2-6.42,0.02-9.63
  c-0.11-2.12-0.26-4.24-0.41-6.35c-0.05-0.74-0.09-1.48-0.2-2.21c-0.33-2.17-0.66-4.33-1.03-6.49c-0.28-1.6-0.59-3.2-0.96-4.79
  c-0.36-1.56-0.77-3.11-1.22-4.64c-0.75-2.52-1.48-5.06-2.35-7.54c-1.08-3.06-2.31-6.07-3.7-9.01
  C225.31,82.08,224.93,81.31,224.53,80.48z M32.61,177.66c3.14,6.1,6.77,11.72,10.92,16.99c9.14,11.58,20.18,20.94,33.13,28
  c14.1,7.69,29.2,11.97,45.23,12.9c12.55,0.73,24.87-0.69,36.92-4.29c15.61-4.66,29.48-12.47,41.52-23.43
  c14.92-13.58,25.27-30.08,31.01-49.43c1.86-6.29,3.12-12.7,3.74-19.25c-0.5-0.15-0.88-0.06-1.26,0.03
  c-0.19,0.05-0.37,0.13-0.57,0.16c-1.28,0.24-2.57,0.47-3.85,0.72c-1.11,0.21-2.22,0.44-3.34,0.64c-1.21,0.23-2.42,0.43-3.63,0.66
  c-1.26,0.24-2.51,0.5-3.77,0.73c-1.09,0.21-2.18,0.38-3.27,0.59c-1.28,0.25-2.56,0.53-3.84,0.77c-2.08,0.4-4.17,0.76-6.25,1.16
  c-1.64,0.31-3.28,0.67-4.93,0.98c-1.69,0.32-3.39,0.61-5.09,0.93c-2.83,0.53-5.66,1.07-8.49,1.61c-0.56,0.11-1.11,0.21-1.67,0.32
  c-1.64,0.33-3.29,0.66-4.93,0.98c-1.26,0.24-2.52,0.46-3.77,0.7c-1.21,0.23-2.42,0.47-3.63,0.7c-1.14,0.21-2.28,0.41-3.42,0.63
  c-1.31,0.25-2.61,0.51-3.91,0.77c-1.19,0.23-2.37,0.46-3.56,0.69c-1.21,0.23-2.42,0.45-3.63,0.68c-1.16,0.22-2.32,0.45-3.49,0.68
  c-1.09,0.21-2.18,0.43-3.27,0.63c-1.23,0.23-2.47,0.44-3.71,0.67c-1.65,0.31-3.29,0.65-4.93,0.97c-1.23,0.24-2.47,0.45-3.7,0.68
  c-1.24,0.23-2.47,0.48-3.7,0.72c-1.14,0.22-2.28,0.42-3.42,0.64c-1.28,0.25-2.56,0.51-3.84,0.75c-1.09,0.21-2.18,0.39-3.27,0.6
  c-1.33,0.25-2.66,0.52-3.99,0.78c-1.14,0.22-2.28,0.44-3.41,0.66c-1.14,0.22-2.27,0.45-3.41,0.66c-1.21,0.23-2.43,0.43-3.64,0.66
  c-1.65,0.31-3.29,0.65-4.93,0.97c-1.23,0.24-2.47,0.45-3.7,0.68c-1.24,0.23-2.47,0.48-3.7,0.72c-1.14,0.22-2.28,0.42-3.41,0.64
  c-1.28,0.25-2.56,0.51-3.84,0.75c-1.11,0.21-2.23,0.4-3.34,0.62c-1.21,0.23-2.41,0.49-3.62,0.72c-1.23,0.24-2.47,0.46-3.71,0.69
  c-1.14,0.22-2.27,0.45-3.41,0.66c-1.21,0.23-2.42,0.43-3.63,0.67c-1.65,0.32-3.29,0.65-4.93,0.97c-1.23,0.24-2.47,0.45-3.7,0.69
  c-1.24,0.23-2.47,0.48-3.7,0.72c-1.14,0.22-2.28,0.42-3.41,0.64c-1.28,0.25-2.56,0.51-3.84,0.75c-1.07,0.2-2.14,0.37-3.2,0.58
  c-1.72,0.33-3.43,0.69-5.14,1.02c-2.11,0.4-4.22,0.77-6.33,1.17c-1.82,0.34-3.63,0.69-5.44,1.05
  C33.24,177.44,32.99,177.54,32.61,177.66z M21.14,116.56c0.21,0.04,0.31,0.09,0.4,0.07c0.58-0.1,1.16-0.21,1.74-0.33
  c1.33-0.26,2.66-0.52,3.99-0.78c1.16-0.23,2.32-0.46,3.48-0.68c1.11-0.22,2.22-0.44,3.34-0.64c1.16-0.22,2.33-0.4,3.49-0.63
  c1.67-0.32,3.33-0.67,5-0.99c1.31-0.25,2.62-0.48,3.92-0.73c1.11-0.21,2.23-0.43,3.34-0.64c1.23-0.23,2.47-0.45,3.7-0.69
  c1.21-0.23,2.42-0.47,3.63-0.7c1.11-0.21,2.23-0.39,3.35-0.6c1.35-0.26,2.71-0.54,4.06-0.8c1.06-0.21,2.13-0.4,3.2-0.6
  c1.23-0.24,2.46-0.49,3.69-0.73c1.16-0.22,2.33-0.43,3.49-0.65c1.67-0.32,3.33-0.66,5-0.99c1.28-0.25,2.57-0.48,3.85-0.72
  c1.21-0.22,2.42-0.45,3.63-0.67c1.14-0.21,2.28-0.43,3.41-0.65c1.19-0.23,2.37-0.48,3.55-0.7c1.67-0.32,3.34-0.62,5.01-0.94
  c1.23-0.23,2.47-0.48,3.7-0.71c1.14-0.22,2.28-0.42,3.41-0.64c1.21-0.23,2.41-0.49,3.62-0.72c1.21-0.23,2.43-0.44,3.64-0.68
  c1.14-0.22,2.27-0.46,3.4-0.68c0.73-0.14,1.46-0.23,2.19-0.37c1.64-0.32,3.28-0.67,4.93-0.98c1.79-0.34,3.58-0.66,5.37-1
  c1.11-0.21,2.23-0.43,3.34-0.64c1.23-0.23,2.47-0.45,3.7-0.69c1.23-0.23,2.47-0.48,3.7-0.71c1.11-0.21,2.23-0.41,3.34-0.63
  c1.23-0.24,2.46-0.5,3.69-0.74c1.21-0.23,2.42-0.45,3.63-0.68c1.14-0.22,2.27-0.45,3.4-0.67c1.33-0.25,2.66-0.5,3.99-0.74
  c1.19-0.22,2.37-0.44,3.56-0.66c1.11-0.21,2.23-0.41,3.34-0.63c1.26-0.24,2.51-0.5,3.77-0.74c1.11-0.21,2.23-0.39,3.34-0.61
  c1.23-0.24,2.46-0.51,3.69-0.75c1.69-0.33,3.39-0.63,5.08-0.96c1.16-0.22,2.32-0.46,3.48-0.68c1.26-0.24,2.52-0.47,3.78-0.71
  c1.11-0.21,2.22-0.44,3.33-0.65c1.28-0.24,2.57-0.47,3.85-0.72c1.14-0.21,2.27-0.44,3.41-0.65c1.19-0.22,2.37-0.42,3.56-0.65
  c1.26-0.24,2.51-0.51,3.77-0.75c1.09-0.21,2.18-0.37,3.27-0.58c1.21-0.24,2.4-0.52,3.61-0.76c1.84-0.36,3.67-0.7,5.51-1.05
  c0.6-0.11,1.21-0.22,1.81-0.33c0.7-0.13,1.41-0.24,2.1-0.39c0.17-0.04,0.33-0.16,0.55-0.28c-11.18-20.7-27.14-36.4-48.25-46.61
  c-23.65-11.45-48.36-13.66-73.85-7.43C77.69,30.24,58.3,42.86,43.28,62C30.71,78.02,23.43,96.26,21.14,116.56z"
      />
      <path
        d="M139.18,143.04c-0.17-0.93-0.33-1.77-0.5-2.67c2.38-0.46,4.67-0.91,7.04-1.37c-0.13-0.22-0.21-0.39-0.32-0.53
  c-1.75-2.24-3.51-4.46-5.25-6.71c-0.29-0.37-0.57-0.46-1.01-0.37c-4.19,0.83-8.38,1.64-12.57,2.45c-2.08,0.4-4.16,0.81-6.25,1.2
  c-0.34,0.06-0.5,0.2-0.6,0.53c-0.86,2.91-1.73,5.82-2.6,8.72c-0.01,0.02,0.01,0.05,0.03,0.18c2.34-0.33,4.64-0.92,7.08-1.29
  c0.17,0.87,0.33,1.71,0.51,2.64c-5.58,1.08-11.11,2.15-16.72,3.23c-0.18-0.91-0.34-1.74-0.52-2.66c2.12-0.41,4.19-0.82,6.34-1.24
  c3.02-10.09,6.04-20.2,9.1-30.44c-3.1,0.59-6.06,1.16-9.12,1.74c-0.17-0.91-0.33-1.74-0.5-2.65c4.73-0.91,9.37-1.81,14.03-2.71
  c0.21,0.25,0.4,0.48,0.58,0.71c6.85,8.59,13.7,17.19,20.54,25.78c0.11,0.14,0.23,0.26,0.32,0.41c0.19,0.29,0.41,0.35,0.76,0.28
  c1.76-0.36,3.54-0.69,5.3-1.03c0.17-0.03,0.33-0.05,0.57-0.08c0.17,0.86,0.33,1.7,0.52,2.64
  C150.36,140.88,144.82,141.95,139.18,143.04z M137.8,128.88c-3.93-4.92-7.74-9.69-11.58-14.5c-0.44,0.85-5.28,17.14-5.28,17.75
  C126.52,131.06,132.08,129.99,137.8,128.88z"
      />
      <path
        d="M174.34,136.26c-0.18-0.92-0.34-1.74-0.52-2.66c2.3-0.45,4.56-0.9,6.89-1.35c-1.78-9.24-3.55-18.4-5.33-27.64
  c-2.34,0.44-4.58,0.87-6.9,1.31c-0.17-0.9-0.34-1.74-0.51-2.63c1.09-0.22,2.13-0.43,3.16-0.63c7.12-1.38,14.24-2.76,21.37-4.12
  c2.19-0.42,4.39-0.73,6.62-0.64c1.59,0.06,3.14,0.29,4.63,0.9c1.91,0.78,3.32,2.11,4.2,3.97c0.99,2.1,1.4,4.3,1.2,6.62
  c-0.16,1.9-0.93,3.51-2.25,4.88c-1.17,1.21-2.59,2.05-4.11,2.72c-2.16,0.95-4.44,1.49-6.74,1.93c-4.65,0.89-9.3,1.79-13.95,2.69
  c-0.07,0.01-0.14,0.05-0.2,0.07c-0.07,0.57,1.47,8.85,1.87,9.99c2.23-0.43,4.48-0.85,6.79-1.3c0.17,0.88,0.34,1.72,0.53,2.67
  C185.49,134.1,179.96,135.17,174.34,136.26z M181.2,118.86c0.39-0.07,0.68-0.11,0.97-0.16c4.77-0.91,9.55-1.83,14.32-2.75
  c1.99-0.38,3.95-0.86,5.78-1.78c1.9-0.95,3.23-2.39,3.53-4.54c0.33-2.3-0.13-4.48-1.35-6.47c-0.55-0.91-1.39-1.53-2.39-1.91
  c-1.29-0.49-2.65-0.64-4.01-0.52c-1.72,0.15-3.44,0.33-5.14,0.63c-3.81,0.68-7.61,1.43-11.42,2.16c-1.01,0.19-2.02,0.4-3.14,0.63
  C179.32,109.06,180.25,113.91,181.2,118.86z"
      />
      <path
        d="M79.61,123.77c-0.22-1.17-0.41-2.21-0.61-3.3c0.9-0.18,1.73-0.34,2.57-0.5c0.06,0.11,0.11,0.17,0.13,0.24
  c0.78,3.97,1.55,7.94,2.34,12c-0.85,0.27-1.71,0.38-2.66,0.54c-0.1-0.49-0.21-0.94-0.28-1.39c-0.25-1.5-0.81-2.84-1.98-3.87
  c-0.86-0.76-1.84-1.33-2.91-1.74c-1.71-0.64-3.48-0.93-5.29-0.94c-4.95-0.05-9.66,1-14.03,3.35c-1.71,0.92-3.21,2.12-4.42,3.66
  c-1.73,2.2-2.51,4.73-2.55,7.5c-0.04,2.58,0.4,5.07,1.43,7.43c1.75,4.02,4.84,6.45,9.08,7.46c2.46,0.58,4.94,0.61,7.44,0.35
  c2.46-0.26,4.87-0.77,7.19-1.67c2.33-0.91,4.45-2.14,6.17-3.99c1.08-1.16,1.89-2.47,2.3-4.01c0.03-0.12,0.08-0.23,0.15-0.41
  c1.01-0.2,2.04-0.4,3.15-0.62c-0.01,0.18,0,0.32-0.03,0.45c-0.87,3.66-2.95,6.47-5.99,8.62c-2.64,1.87-5.6,3-8.73,3.73
  c-2.39,0.56-4.81,0.88-7.26,0.87c-2.68-0.01-5.32-0.36-7.84-1.35c-4.85-1.91-8-5.4-9.48-10.38c-0.72-2.43-1.06-4.91-0.79-7.46
  c0.36-3.33,1.66-6.23,3.9-8.7c2.17-2.39,4.85-4.03,7.81-5.23c2.97-1.21,6.07-1.91,9.25-2.24c2.8-0.29,5.58-0.23,8.31,0.47
  c0.93,0.24,1.84,0.57,2.75,0.86C78.97,123.58,79.23,123.65,79.61,123.77z"
      />
      <path
        d="M91.81,170.36c0.26-0.08,0.48-0.18,0.71-0.23c1.23-0.23,2.47-0.45,3.7-0.68c1.65-0.32,3.29-0.65,4.93-0.97
  c1.19-0.23,2.38-0.43,3.56-0.65c1.16-0.22,2.32-0.45,3.48-0.67c1.19-0.23,2.37-0.45,3.56-0.68c1.26-0.24,2.51-0.49,3.77-0.73
  c1.11-0.21,2.23-0.41,3.34-0.63c1.28-0.25,2.56-0.51,3.85-0.75c1.18-0.23,2.37-0.43,3.56-0.66c1.14-0.21,2.27-0.44,3.41-0.66
  c1.28-0.24,2.57-0.47,3.85-0.72c1.64-0.32,3.29-0.65,4.93-0.97c1.19-0.23,2.38-0.43,3.56-0.65c1.16-0.22,2.32-0.45,3.48-0.67
  c1.16-0.22,2.32-0.45,3.49-0.67c1.28-0.25,2.56-0.5,3.84-0.74c1.14-0.22,2.28-0.42,3.42-0.64c1.26-0.24,2.51-0.5,3.77-0.74
  c1.18-0.23,2.37-0.43,3.56-0.66c1.14-0.21,2.27-0.44,3.41-0.66c1.28-0.24,2.57-0.47,3.85-0.72c1.64-0.32,3.28-0.65,4.93-0.97
  c0.64-0.13,1.29-0.23,2.08-0.37c-2.97,5.71-6.75,10.62-11.34,14.9c-6.57,6.13-14.19,10.49-22.84,12.94
  c-10.02,2.84-20.14,3-30.17,0.21C103.05,179.03,96.3,174.35,91.81,170.36z"
      />
      <path
        d="M164.62,85.17c-0.17,0.12-0.24,0.2-0.32,0.22c-1.57,0.3-3.14,0.6-4.72,0.89c-1.14,0.22-2.28,0.44-3.41,0.66
  c-1.26,0.24-2.52,0.47-3.77,0.71c-1.14,0.22-2.27,0.44-3.41,0.65c-1.28,0.24-2.57,0.47-3.85,0.72c-1.72,0.34-3.43,0.7-5.14,1.03
  c-1.11,0.22-2.24,0.39-3.35,0.6c-1.23,0.24-2.46,0.51-3.69,0.75c-1.09,0.21-2.18,0.39-3.27,0.6c-1.31,0.25-2.61,0.53-3.91,0.78
  c-1.14,0.22-2.28,0.41-3.42,0.62c-1.16,0.22-2.32,0.44-3.49,0.66c-1.26,0.24-2.52,0.47-3.78,0.71c-1.62,0.31-3.24,0.62-4.86,0.93
  c-0.63,0.12-1.26,0.25-1.89,0.38c-1.91,0.37-3.82,0.75-5.73,1.11c-1.11,0.21-2.24,0.38-3.35,0.59c-1.23,0.24-2.46,0.51-3.69,0.75
  c-1.04,0.2-2.09,0.37-3.13,0.57c-1.33,0.25-2.66,0.53-3.99,0.79c-1.16,0.22-2.33,0.41-3.49,0.63c-1.14,0.21-2.28,0.44-3.41,0.66
  c-0.65,0.13-1.3,0.27-1.96,0.39c-0.25,0.04-0.52,0.03-0.95,0.05c2.93-5.61,6.63-10.42,11.11-14.63
  c7.61-7.14,16.51-11.89,26.73-14.03c11.13-2.34,22.02-1.43,32.6,2.79C154.76,78,159.93,81.12,164.62,85.17z"
      />
    </svg>
  );
}

export default Logo;
